import React from "react";
import "./Statements.css";

export default function Statements() {
  return (
    <div className="col-md-11">
      <h3 className="statement-heading">Mission</h3>
      <p className="statement-contents">
        Mission Statement: "At Shree Koderma Gaushala, we are dedicated to the
        holistic well-being of our beloved cows, fostering their health,
        happiness, and spiritual significance. We strive to uphold the
        principles of ethical and sustainable dairy farming, promoting the
        welfare of our community and the environment. Through our unwavering
        commitment to the sacred cow, we aim to inspire a compassionate and
        harmonious society, rooted in the values of love, care, and respect for
        all living beings."
      </p>

      <h3 className="statement-heading">Vision</h3>
      <p className="statement-contents">
        Shree Koderma Gaushala envisions a future where the divine bond between
        humans and cows flourishes, creating a haven of love and prosperity for
        both. We aspire to be a beacon of excellence in ethical and sustainable
        dairy practices, setting a standard for others to follow. By integrating
        traditional wisdom with modern innovation, we aim to be a source of
        inspiration, education, and empowerment, fostering a community that
        cherishes and protects the sacred essence of our bovine companions. Our
        vision is to create a world where every cow is revered, every farmer is
        empowered, and every individual is connected to the inherent grace of
        the divine through our commitment to cow welfare and sustainable living.
      </p>
      <h3 className="statement-heading">The Genesis of Our Online Presence</h3>
      <p className="statement-contents">
        Avinash Seth, the dedicated Treasurer of Shree Koderma Gaushala Samity
        and Director of Grizzly Vidyalaya, envisioned the integration of
        technology to propel our gaushala into the digital age. His foresight
        led to the creation and successful execution of the gaushala's website,
        providing a seamless platform for accepting donations. This
        technological leap ensures that contributing to the welfare of our cows
        is just a click away. We are immensely grateful for his commitment to
        the cause and innovative approach in making a positive impact on the
        lives of our beloved cows.
      </p>
    </div>
  );
}
