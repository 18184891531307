import React from 'react'
import Routes from './routes'
import Header from './components/common/Header/Header'
import Footer from './components/common/Footer/Footer'

export default function App() {
  return (
    <div>
      
      <Routes/>
      
    </div>
  )
}
